import axios from 'axios'
import router from '@/router/index.js'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token')
    token && (config.headers.Authorization = token)
    return config
  },
  (err) => {
    console.log(err)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (success) => {
    // 业务逻辑错误
    if (success.status && success.status === 200) {
      if (success.data.status === 4001) {
        if (success.data.message) {
          Message.error({ message: success.data.message })
        }
        Cookies.remove('token')
        router.replace({
          path: '/login'
        })
        return
      }
      if (success.data.status === 5001) {
        if (success.data.message) {
          Message.error({ message: success.data.message })
        }
        return
      }
      if (success.data.message) {
        Message.success({ message: success.data.message })
      }
    }
    return success.data
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 4001:
          Cookies.remove('token')
          router.replace({
            path: '/login'
          })
          Message.error({ message: '请先登录' })
          break
        default:
          if (error.response.message) {
            Message.error({ message: error.response.message })
          } else {
            Message.error({ message: '未知错误' })
          }
      }
    }
  })

const base = '/b_api'

export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${base}/${url}`,
    data: params
  })
}

export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${base}/${url}`,
    data: params
  })
}

export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${base}/${url}`,
    data: params
  })
}

export const deleteRequest = (url, params) => {
  return axios({
    method: 'delete',
    url: `${base}/${url}`,
    data: params
  })
}
