<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'


export default defineComponent({
  setup() {

  },
  methods: {
    // bodyScale() {
    //   var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可视区域宽度
    //   // console.log("now",devicewidth )
    //   var scale = devicewidth / 1920; // 除以设计稿的尺寸
    //   document.body.style.zoom = scale; // 放大缩小相应倍数
    //   // 设置页面根节点字体大小
    //   document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'

    // },
  },
  created() {
    // this.bodyScale()
  },

})

</script>


<style>

</style>
