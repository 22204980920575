import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Viewer from 'v-viewer'
import './config/rem'
import '@/styles/index.scss' // global css
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import { postRequest, putRequest, getRequest, deleteRequest } from './utils/api'

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

import * as echarts from 'echarts' // 将echarts引入与到vue的原型中，方便使用

// 修改 el-dialog 只能点击叉号关闭对话框
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.Dialog.props.closeOnPressEscape.default = false

// 全局注册自定义指令 设置一定时间内只能点击一次
Vue.directive('resetClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.cursor = 'not-allowed'
        setTimeout(() => {
          el.disabled = false
          el.style.cursor = 'pointer'
        }, binding.value || 1000)
      }
    })
  }
})

// 在main.js中进行全局引入
import VueQriously from 'vue-qriously'

import VueClipboard from 'vue-clipboard2'
const options = {
  fullscreenEl: false
}
Vue.use(preview, options)
Vue.use(preview)
Vue.prototype.$echarts = echarts
Vue.use(VueQriously)
Vue.use(VueClipboard)

Vue.use(preview)

Vue.config.productionTip = false
// Vue.use(ElementUI)
Vue.use(ElementUI, { locale })
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
Vue.prototype.postRequest = postRequest
Vue.prototype.getRequest = getRequest
Vue.prototype.putRequest = putRequest
Vue.prototype.deleteRequest = deleteRequest
// Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
