import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import store from '@/store'
import Cookies from 'js-cookie'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/kuaishouauth',
    name: 'kuaishouauth',
    component: () => import('../components/kuaishouauth/kuaishou.vue'),
    meta: {
      meta: {
        title: '快手电商授权'
      }
    }
  },
  {
    path: '/douyinauth',
    name: 'douyinauth',
    component: () => import('../components/douyinauth/douyinauth.vue'),
    meta: {
      meta: {
        title: '授权'
      }
    }
  },
  {
    path: '/kuaishouVideo',
    name: 'kuaishouauthVideo',
    component: () => import('../components/kuaishouauth/kuaishouVideo.vue'),
    meta: {
      meta: {
        title: '快手平台授权'
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '样叽登录111'
    }
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    // component: () => import('../views/Index.vue'),
    component: resolve => require(['../views/Index.vue'], resolve),
    children: [
      {
        path: 'index',
        name: 'Index',
        component: resolve => require(['@/components/Index/Index.vue'], resolve),
        meta: {
          title: '样叽|企业端'
        }
      },
      {
        path: 'project',
        name: 'Project',
        component: resolve => require(['@/views/ProjectCenter/ProjectCenter.vue'], resolve),
        meta: {
          title: '样叽|项目中心'
        }
      },
      {
        path: 'selection',
        name: 'Selection',
        component: resolve => require(['@/views/SelectionCenter/SelectionCenter.vue'], resolve),
        meta: {
          title: '样叽|选品广场'
        },
        children: [
          {
            path: 'mySelection',
            name: 'mySelection',
            component: resolve => require(['@/views/SelectionCenter/MySelection/MySelection.vue'], resolve),
            meta: {
              title: '样叽|我的选品'
            },
          },
          {
            path: 'selectionSquare',
            name: 'selectionSquare',
            component: resolve => require(['@/views/SelectionCenter/SelectionSquare/SelectionSquare.vue'], resolve),
            meta: {
              title: '样叽|选品广场'
            },
          },
          {
            path: 'mySelectionDetail:id',
            name: 'mySelectionDetail',
            component: resolve => require(['@/views/SelectionCenter/MySelection/SelectionInfo.vue'], resolve),
            meta: {
              title: '样叽|选品详情'
            },
          },
          {
            path: 'selectionSquareDetail:id',
            name: 'selectionSquareDetail',
            component: resolve => require(['@/views/SelectionCenter/SelectionSquare/SelectionDetail.vue'], resolve),
            meta: {
              title: '样叽|选品详情'
            },
          },
        ]
      },
      {
        path: 'workspace',
        name: 'WorkSpace',
        component: resolve => require(['@/views/WorkSpace.vue'], resolve),
        meta: {
          title: '样叽|工作台'
        }
      },
      {
        path: 'myyoungee',
        name: 'MyYoungee',
        component: resolve => require(['@/views/MyYoungee.vue'], resolve),
        meta: {
          title: '样叽|我的样叽'
        }
      },
      {
        path: 'datacenter',
        name: 'DateCenter',
        component: resolve => require(['@/views/DataCenter/DataCenter.vue'], resolve),
        meta: {
          title: '样叽|数据中心'
        }
      },
      {
        path: 'usercenter',
        name: 'UserCenter',
        component: resolve => require(['@/components/Index/UserCenter.vue'], resolve),
        meta: {
          title: '样叽|用户中心'
        }
      },
      {
        path: 'createproject',
        name: 'CreateProject',
        component: resolve => require(['@/components/CreateProject/CreateProject.vue'], resolve),
        children: [
          {
            path: '/createproject/addnewproduct',
            name: 'AddNewProduct',
            component: resolve => require(['@/components/CreateProject/AddNewProduct.vue'], resolve)
          }
        ]
      },
      {
        path: '/createproject/recruitrequirement',
        name: 'RecruitRequirement',
        component: resolve => require(['@/components/CreateProject/RecruitRequirement.vue'], resolve)
      },
      {
        path: '/createproject/excuterequirement',
        name: 'ExcuteRequirement',
        component: resolve => require(['@/components/CreateProject/ExcuteRequirement.vue'], resolve)
      },
      {
        path: '/viewproject',
        name: 'ViewProject',
        component: resolve => require(['@/components/ProjectCenter/ProjectInfo/ViewProject.vue'], resolve)
      },
      {
        path: '/viewhome',
        name: 'ViewHome',
        component: resolve => require(['@/components/ProjectCenter/Index.vue'], resolve),
        meta: {
          title: '样叽|项目预览'
        }
      }
    ],
    // children: [
    //   {
    //     path: 'index',
    //     name: 'Index',
    //     component: () => import('@/components/Index/Index.vue'),
    //     meta: {
    //       title: '样叽|企业端'
    //     }
    //   },
    //   {
    //     path: 'project',
    //     name: 'Project',
    //     component: () => import('@/views/ProjectCenter.vue'),
    //     meta: {
    //       title: '样叽|项目中心'
    //     }
    //   },
    //   {
    //     path: 'workspace',
    //     name: 'WorkSpace',
    //     component: () => import('@/views/WorkSpace.vue'),
    //     meta: {
    //       title: '样叽|工作台'
    //     }
    //   },
    //   {
    //     path: 'myyoungee',
    //     name: 'MyYoungee',
    //     component: () => import('@/views/MyYoungee.vue'),
    //     meta: {
    //       title: '样叽|我的样叽'
    //     }
    //   },
    //   {
    //     path: 'usercenter',
    //     name: 'UserCenter',
    //     component: () => import('@/components/Index/UserCenter.vue'),
    //     meta: {
    //       title: '样叽|用户中心'
    //     }
    //   },
    //   {
    //     path: 'createproject',
    //     name: 'CreateProject',
    //     component: () => import('@/components/CreateProject/CreateProject.vue'),
    //     children: [
    //       {
    //         path: '/createproject/addnewproduct',
    //         name: 'AddNewProduct',
    //         component: () => import('@/components/CreateProject/AddNewProduct.vue')
    //       }
    //     ]
    //   },
    //   {
    //     path: '/createproject/recruitrequirement',
    //     name: 'RecruitRequirement',
    //     component: () => import('@/components/CreateProject/RecruitRequirement.vue')
    //   },
    //   {
    //     path: '/createproject/excuterequirement',
    //     name: 'ExcuteRequirement',
    //     component: () => import('@/components/CreateProject/ExcuteRequirement.vue')
    //   },
    //   {
    //     path: '/viewproject',
    //     name: 'ViewProject',
    //     component: () => import('@/components/ProjectCenter/ProjectInfo/ViewProject.vue')
    //   },
    //   {
    //     path: '/viewhome',
    //     name: 'ViewHome',
    //     component: () => import('@/components/ProjectCenter/Index.vue'),
    //     meta: {
    //       title: '样叽|项目预览'
    //     }
    //   }
    // ],

    redirect: 'index'
  }

]

const router = new VueRouter({
  mode: 'history',
  history: false,
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);   // chrome
  // 获取用户token
  const token = Cookies.get('token')
  // 不要拦截/kuaishouauth的请求页面
  if (to.path === '/kuaishouauth'  || to.path === '/kuaishouVideo' || to.path === '/douyinauth') {
    //如果目标路由是/kuaishouauth，则直接允许导航，不进行拦截  
    next();
  } else {
    if (token) {
      store.state.isLogin = true
      next()
      if (to.path === '/login' || from.path === '/login') {
        next('/index')
      }
    } else {
      store.state.isLogin = false
      next()
      console.log("to", to.path, "from", from.path)
      // 如果用户token不存在则跳转到login页面
      if (to.path === '/index' || to.path === '/login' || from.path === '/login') {
        next()
      } else {
        Message.error('请先登录！')
        next('/login')
      }
    }
  }

})


router.afterEach((to, from) => {
  document.title = to.meta.title //在全局后置守卫中获取路由元信息设置title
})

export default router
