import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    newProject: {},
    project: {},
    recruit_strategys: [],
    isCreatingProject: true,

    platformIconList: [
      {
        name: '红book',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/platformlogo/redbook.png',
      },
      {
        name: '红book',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/platformlogo/redbook.png',
      },
      {
        name: '抖音',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/pingtai2.png',
      },
      {
        name: '微博',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/lQLPDhrXwll1_OojIrB54K2_gW0cQQGOvh1TQE8B_34_35.png',
      },
      {
        name: '快手',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/lQLPDhrXwll1_NcjI7AD0T3viYtxQwGOvh1SwG0A_35_35.png',
      },
      {
        name: 'b站',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/lQLPDhrXwll1_N8lJbAnowYY8vg2EwGOvh1MQAcA_37_37.png',
      },
      {
        name: '大众点评',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/lQLPDhrXwll1_NomJrBzk2H1dD_6NwGOvh1TQE8A_38_38.png',
      },
      {
        name: '知乎',
        icon: 'https://horastar.obs.cn-east-3.myhuaweicloud.com/talent/lQLPDhrXwll1_OglJrDwCzIdgTtsKQGOvh1TAG0A_38_37.png',
      },
    ],

    product: {
      product_id: "3",
      product_name: "虎皮凤爪",
      product_type: "实体商品寄拍",
      shop_address: "",
      product_price: "49.9",
      product_detail: "小蜀仙虎皮凤爪，两袋装49.9元",
      product_url: "https://v.douyin.com/SLvJB75/",
      created_at: "2023-02-28 01:04:47",
      updated_at: "2023-04-24 22:23:28",
      brand_name: "小蜀仙",

      MainPhotoList: [
        {
          symbol: 1,
          url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/836458e2-4a6f-4bce-955f-3c6bde6dba93.png",
          photo_uid: "1677517398890",
        },
      ],
      CarousePhotolList: [
        {
          symbol: 2,
          url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/9a0acc05-5540-4660-afa0-e6da732a1228.png",
          photo_uid: "1677517419177",
        },
        {
          symbol: 2,
          url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/0b4a1a51-46c2-4fd5-8d41-6647c6da6396.png",
          photo_uid: "1677517419163",
        },
        {
          symbol: 2,
          url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/b91a8183-0324-4d94-88fd-51c882156260.png",
          photo_uid: "1677517419166",
        },
        {
          symbol: 2,
          url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/46a2c649-beab-4b63-991e-4fe9edbbf6e5.png",
          photo_uid: "1677517419151",
        },
      ],
    },

    newSelection: {},
    selection: {
      // enterprise_id: "1234566798787",
      // selection_id: "YG1234567",
      // selection_status: "6",
      // content_type: "视频",
      // task_mode: "悬赏任务",
      // platform: "大众点评",
      // sample_mode: "垫付买样",
      // sample_num: "20",
      // remain_num: "20",
      // commission_rate: "10",
      // estimated_cost: "",
      // task_reward: "100.0",
      // product_url: "https://v.douyin.com/SLvJB75/",
      // sample_condition: "最近30天销量2000+",
      // reward_condition: "最近30天销量2000+",
      // create_at: "2023-05-28 11: 13",
      // submit_at: "2023-05-31 25: 34",
      // pass_at: "2023-06-01 09: 15",
      // task_ddl: "2023-08-31 23: 00",
      // detail:
      //   "一、内容总体方向：下饭菜\n①菜谱第一弹，冷吃卤味碟！软烂耙糯的鸡爪唇齿留香，答应我一定要吃！\n②过年凡尔赛的冷吃卤味拼盘！\n③教你 1 分钟做一道新年下饭菜~端上桌秒光！\n④晚餐来一道这种下饭菜，直接端上桌，光配辣椒就吃了三碗饭！\n⑤爱吃辣的你无法拒绝它，超下饭的快手菜，上桌后全家抢着吃！\n⑥简单微波炉转一下就是一盘硬菜，咸香鲜辣一吃就停不下来！\n二、视频时长15-20秒\n三、发布文案必带话题#小蜀仙甄选\n四、可挂车分佣，佣金10%\n新手达人有疑问可咨询客服",
      // selection_photos: [
      //   {
      //     symbol: 2,
      //     photo_url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/46a2c649-beab-4b63-991e-4fe9edbbf6e5.png",
      //     photo_uid: "1677517419151",
      //   },
      //   {
      //     symbol: 2,
      //     photo_url: "https://horastar.obs.cn-east-3.myhuaweicloud.com/backstage_upload/b91a8183-0324-4d94-88fd-51c882156260.png",
      //     photo_uid: "1677517419166",
      //   }
      // ],
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
